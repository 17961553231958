<template>
  <div class="step-container">
    <div class="text-center">
      <div class="h6 main step-title" style="margin-top:12px">{{ order.service_name }} 서비스 개설 시작해 볼까요.</div>
      <div style="margin-top:40px">

        <div class="box-step">
          <div class="flex"
               style="margin-top:11px"
               v-for="(st1_c, st1_c_idx) in contents"
               :key="`st1_c-${st1_c_idx}`">
            <img src="/static/icon/u_check.svg" class="svg-primary" style="margin-top:2px;width:20px;height:20px"/>
            <div class="body2 sub2" style="margin-left:8px">{{ st1_c }}</div>
          </div>
        </div>

      </div>
      <div class="flex-center">
        <div class="flex-align" style="max-width:450px;width:100%;margin-bottom:40px">
          <button class="button is-primary body2-medium" @click="clickNext"
                  style="width:100%;height:52px;margin-top:40px;">다음
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "ServiceCreateStep2",
    components: {},
    props: {
      order: {
        type: Object
      }
    },
    created() {
    },
    data() {
      return {
        contents: [
          '내가 생각하던 플랫폼 서비스를 구현해 볼 수 있는 기회!',
          '14일간 무료!',
          '클라우드 기반으로 언제 어디서나 접근하고 관리'
        ]
      }
    },
    computed: {

    },
    methods: {
      clickNext() {
        this.$emit('next');
      }
    }
  }
</script>
<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  @media (min-width: 1025px)
    .v-mobile
      display none

    .plan-select-wrapper
      display grid
      grid-gap 24px
      grid-template-columns repeat(3, 1fr)
    .plan-item
      padding-bottom 12px

    .plan-item:first-child
      padding-top 0
      border-top 0
  @media (max-width: 1024px)
    .step-title
      white-space pre-line
    .step-container
      height 100%
      padding-top 40px
      padding-left 16px
      padding-right 16px

    .plan-select-wrapper
      margin-bottom 10px

    .plan-sale
      padding 12px 0
      text-align center
      border-top 1px solid $gray2
      border-bottom 1px solid $gray2
      margin 12px 0

      img
        vertical-align sub
    .plan-item
      border 0
      padding 10px 0


  .option-st
    border 1px solid $gray1
    border-radius 8px
    margin-bottom 20px


  .tag-primary
    color $primary
    background-color $primary-light2
    border-radius 4px
    font-size 13px
    padding 2px 8px

  .box-step
    max-width 450px
    margin 0 auto
    padding 32px
    background-color $gray4
    border-radius 8px
    text-align left

  .box-info
    margin-top 4px
    background-color $gray3
    width 100%
    padding 10px
    border-radius 8px
    display flex
    align-items center
    justify-content center

    img
      width 16px
      height 16px
      margin-right 4px


  .pc
    .option-st
      padding 20px 24px

    .option-st.selected
      padding 19px 23px

  .mobile
    .option-st
      padding 16px 20px

    .option-st.selected
      padding 15px 19px

  .disabled
    background-color #eee

  .selected
    border 2px solid $primary !important

  .step4-content-wrapper
    background-image url(/static/img/bg_info.svg)
    background-repeat no-repeat
    background-size cover
    background-position center
    height 381px
    padding 40px 50px
    text-align left
    display flex
    align-items center
    justify-content space-between

  .title-s-box
    padding-bottom 12px
    border-bottom 1px solid $gray2

  .hosting-box
    width 100%
    padding-top 60px
    border-top 1px solid $gray
</style>
